import React, { FCWC, useEffect, useRef, useState } from "react"
import { AuthenticationResult, PublicClientApplication, RedirectRequest } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import config from "config"
import AuthenticationTokenProvider from "../AuthenticationTokenProvider/AuthenticationTokenProvider"

export const AuthenticationProvider: FCWC = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [initialized, setInitialized] = useState(false)

    // create a new static instance of the msal client
    const { current: msalInstance } = useRef(new PublicClientApplication(config.azureB2C))

    const isPublic = window.location.pathname.includes("/public/")

    const msalInit = async function () {
        await msalInstance.initialize()

        msalInstance.enableAccountStorageEvents()

        msalInstance.handleRedirectPromise().then(handleResponse).catch(handleError)

        setInitialized(true)
    }

    const handleResponse = async function (response: AuthenticationResult | null) {
        setLoading(false)

        // if the user is in the public view, we don't need to do anything
        if (isPublic) {
            return null
        }

        // if the user is response is not null, we set the active account returned by the response
        if (response !== null) {
            msalInstance.setActiveAccount(response.account)
            return null
        }

        // if the user is not in the public view and the response is null, we check if there is an active account
        const activeAccount = msalInstance.getActiveAccount()
        if (activeAccount) return null

        // if there is no active account, we check if there are any accounts in the cache
        const accounts = msalInstance.getAllAccounts()

        // if there is only one account, we set it as the active account
        if (accounts.length === 1) {
            msalInstance.setActiveAccount(accounts[0])
            return null
        }

        // if there are more than one account, we clear the cache and redirect the user to the login page
        if (accounts.length > 1) {
            await msalInstance["browserStorage"].clear()
            msalInstance["browserStorage"].clearMsalCookies()
        }

        // if there are no accounts in the cache, we redirect the user to the login page
        setLoading(true)

        const request: RedirectRequest = {
            authority: config.azureB2C.auth.authority,
            scopes: config.loginRequest.scopes,
        }

        await msalInstance.loginRedirect(request)
    }

    const handleError: ((reason: any) => void | PromiseLike<void>) | null | undefined = async function (error) {
        // reset password
        if (error?.errorMessage?.indexOf(config.forgetPasswordErrorCode) > -1) {
            const userEmail = error.errorMessage.substring(
                error.errorMessage.lastIndexOf(":") + 2,
                error.errorMessage.length - 2,
            )
            const request: RedirectRequest = {
                authority: config.azureB2C.auth.passwordResetAuthority,
                loginHint: userEmail,
                scopes: config.loginRequest.scopes,
            }

            await msalInstance.loginRedirect(request)
        } else {
            await msalInstance["browserStorage"].clear()
            msalInstance["browserStorage"].clearMsalCookies()
        }
    }

    // initialize the msal instance
    useEffect(() => {
        msalInit()
    }, [])

    if (!initialized) return null

    return (
        <MsalProvider instance={msalInstance}>
            <LoadingPlaceholder loading={loading} message={"Authenticating"}>
                <AuthenticationTokenProvider scopes={config.loginRequest.scopes} isPublic={isPublic}>
                    {children}
                </AuthenticationTokenProvider>
            </LoadingPlaceholder>
        </MsalProvider>
    )
}
